<template>
    <div class="home">
      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form" label-width="180px">
          <el-form-item style="font-weight: bold;font-size: 16px;margin-top: 50px;" label="内容标签">
            <div class="flex" v-for="(item, index) in labels"  :key="index">
                <el-input class="inp" disabled v-model="item.name"></el-input>
                <el-button
                style="margin-left: 20px;"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="deleteData(index)"
                ></el-button>
            </div>
          </el-form-item>
            <el-button
                style="margin-left: 320px;"
                type="primary"
                icon="el-icon-plus"
                circle
                @click="innerVisible  = true"
                ></el-button>

            <el-dialog
            width="80%"
            title="选择标签"
            :visible.sync="innerVisible"
            append-to-body>
                <selectlabel  @getselectlabel='getselectlabel'></selectlabel>
            </el-dialog>

            <el-form-item style="margin-top:20px;margin-left: 125px;">
                <el-button type="primary"  @click="onSubmit">完成</el-button>
            </el-form-item>
        </el-form>
</div>
      <div class="pt20"></div>
    </div>
</template>
<script>

import selectlabel from '../model/selectlabel.vue'

export default {
  name: 'Home',
  components: {
    selectlabel
  },
  data(){
    return {
      labels: [],
      innerVisible :false,
    }
  },
  props: {
    label: Array
  },
  methods: {
    onSubmit() {
      this.$emit("getlabel", this.labels);
    },
    getselectlabel(labe){
       let label = this.labels;
       for(let i in label){
           if(label[i].id == labe.id){
               return
           }
       }
       this.labels.push(labe);
    },
    deleteData(index) {// 删除
        this.labels.splice(index, 1);
    },
  },
  created(){
     this.labels = this.label;
  }
}
</script>
<style scoped>
.form{
    width: 520px;
}
.flex{
    display: flex;margin-bottom: 10px;
}
.inp /deep/ .el-input__inner{
  color: #000;
}
</style>